import { Loan } from '../../Loans/shared/types';

export enum NotificationType {
  Submitted = 'submitted',
  Approved = 'approved',
  EMandate = 'eMandate',
  EMandateApproved = 'eMandateApproved',
  ESignatureCertificateEnrolment = 'eSignatureCertificateEnrolment',
  ESignatureCertificateEnrolmentApproved = 'eSignatureCertificateEnrolmentApproved',
  ESigning = 'eSigning',
  ESigningApproved = 'eSigningApproved',
  VideoAttestation = 'videoAttestation',
  VideoAttestationApproved = 'videoAttestationApproved',
  Disbursement = 'disbursement',
  Declined = 'declined',
  Servicing = 'servicing',
  Active = 'active'
}

export enum NotificationStatus {
  Read = 'read',
  Unread = 'unread'
}

export enum TurnkeyLoanStatus {
  Origination = 'Origination',
  Reprocessing = 'Reprocessing',
  AutoProcessing = 'AutoProcessing',
  WaitingForApproval = 'WaitingForApproval',
  Interview = 'Interview',
  OfferConfirmation = 'OfferConfirmation',
  VideoAttestation = 'VideoAttestation',
  Approved = 'Approved',
  Active = 'Active',
  PastDue = 'PastDue',
  RolloverRequested = 'RolloverRequested',
  Collateral = 'Collateral',
  InitialPaymentsPreDisbursement = 'InitialPayments_PreDisbursement',
  DisbursementFailed = 'DisbursementFailed',
  DisbursementInProgress = 'DisbursementInProgress',
  P2PGatheringInvestments = 'P2P_GatheringInvestments',
  WaitingForCustomerSignature = 'WaitingForCustomerSignature',
  BankAccountVerification = 'BankAccount_Verification',
  Repaid = 'Closed_Repaid',
  Cancelled = 'Closed_Cancelled',
  WrittenOff = 'Closed_WrittenOff',
  Rejected = 'Rejected'
}
export interface NotificationResponse {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  userId: string;
  loanId: string;
  status: NotificationStatus;
  message: string;
  type: NotificationType;
  loanMilestone: NotificationType;
  turnkeyLoanStatus: TurnkeyLoanStatus;
  loan: Loan;
}

export interface GetNotificationsQueryParams {
  page: number;
  limit?: number;
}
