import { AxiosError } from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import useCustomToast from '../../../hooks/useCustomToast';
import { axios } from '../../../lib/axios';
import { loanDraftKeys } from '../types/queryKeyFactory';
import { LoanApplicationFormDTO } from '../routes/ApplyLoan';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export interface UpdateDraft {
  loanId: string | null;
  loanApplication: LoanApplicationFormDTO;
}

const saveDraft = (data: LoanApplicationFormDTO): Promise<LoanApplicationFormDTO> => {
  return axios.put('/loans/save-draft', data);
};

export const useSaveDraftMutation = () => {
  const { t } = useTranslation('form');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addCustomToast } = useCustomToast();

  return useMutation({
    mutationFn: saveDraft,
    onMutate: async () => {
      await queryClient.cancelQueries(loanDraftKeys.all);

      const previousLoan = queryClient.getQueryData(loanDraftKeys.all);

      return { previousLoan };
    },
    onError: (err, _, context) => {
      if (context?.previousLoan) {
        queryClient.setQueryData(loanDraftKeys.all, context.previousLoan);
      }

      if (err instanceof AxiosError) {
        if (err.response?.data) {
          addCustomToast({
            title: t('Something went wrong'),
            status: 'error'
          });

          return err;
        }
      }
    },
    onSuccess: (loan) => {
      queryClient.setQueryData(loanDraftKeys.all, loan);
      navigate('/loans');

      addCustomToast({
        title: t('Application has been saved'),
        status: 'success'
      });
    }
  });
};

const updateDraft = (data: UpdateDraft): Promise<LoanApplicationFormDTO> => {
  return axios.put(`/loans/${data.loanId}/update-draft`, data.loanApplication);
};

export const useUpdateDraftMutation = () => {
  const { t } = useTranslation('form');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addCustomToast } = useCustomToast();

  return useMutation({
    mutationFn: updateDraft,
    onMutate: async () => {
      await queryClient.cancelQueries(loanDraftKeys.all);

      const previousLoan = queryClient.getQueryData(loanDraftKeys.all);

      return { previousLoan };
    },
    onError: (err: unknown) => {
      if (err instanceof AxiosError) {
        if (err.response?.data) {
          addCustomToast({
            title: t('Something went wrong'),
            status: 'error'
          });

          return err;
        }
      }
    },
    onSuccess: (loan: any) => {
      queryClient.setQueryData(loanDraftKeys.all, loan);
      navigate('/loans');

      addCustomToast({
        title: t('Application has been saved'),
        status: 'success'
      });
    }
  });
};

const deleteDraft = (loanId: string): Promise<{ status: string; id: string; msg: string }> => {
  return axios.delete(`/loans/${loanId}/delete-draft`);
};

export const useDeleteDraftMutation = () => {
  const { t } = useTranslation('form');
  const queryClient = useQueryClient();
  const { addCustomToast } = useCustomToast();

  return useMutation({
    mutationFn: deleteDraft,
    onMutate: async () => {
      await queryClient.cancelQueries(loanDraftKeys.all);

      const previousLoan = queryClient.getQueryData(loanDraftKeys.all);

      return { previousLoan };
    },
    onError: (err: unknown) => {
      if (err instanceof AxiosError) {
        if (err.response?.data) {
          addCustomToast({
            title: t('Something went wrong'),
            status: 'error'
          });

          return err;
        }
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries(['draftLoans']);

      addCustomToast({
        title: t('Application has been deleted'),
        status: 'success'
      });
    }
  });
};
