import { Flex, Heading, Text, Button, Box, HStack, Divider, Image, Link } from '@chakra-ui/react';
import { BsFacebook, BsApple } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { Link as ReactRouterLink, NavLink } from 'react-router-dom';
import { CustomFade } from '../../../../components/Motion';
import { Footer } from '../../../../components/Layout';

type AuthLayoutProps = {
  header: string;
  subHeader: string;
  subText: string;
  coverImgSrc: string;
  textBottom: string;
  linkBottom: string;
  linkTo: string;
  children: React.ReactNode;
};

type Icon = {
  id: string;
  text: string;
  icon: React.ReactElement;
};

type IconProps = {
  icons: Icon[];
};

const iconsList = [
  { id: 'google', text: 'Google', icon: <FcGoogle /> },
  { id: 'apple', text: 'Apple ID', icon: <BsApple color="#000" /> },
  { id: 'facebook', text: 'Facebook', icon: <BsFacebook color="#4267B2" /> }
].filter(Boolean) as Icon[];

const SocialMediaIcons = ({ icons }: IconProps) => {
  return (
    <Flex
      width="100%"
      direction={{
        base: 'column',
        lg: 'row'
      }}
      gap="12px"
      justifyContent="center"
    >
      {icons.map((item) => (
        <Button
          key={item.id}
          leftIcon={item.icon}
          size="lg"
          variant="ghostOutline"
          fontSize="20px"
          lineHeight="120%"
          color="neutral.900"
          border="1px solid #6b7280"
          width={['100%', 'auto']}
          as="a"
          href={`${process.env.REACT_APP_API_URL}/auth/${item.id}`}
        >
          {item.text}
        </Button>
      ))}
    </Flex>
  );
};

export const AuthFormLayout = ({ header, subHeader, children, subText, coverImgSrc, textBottom, linkBottom, linkTo }: AuthLayoutProps) => (
  <>
    <Flex direction={{ base: 'column', lg: 'row' }}>
      <Flex
        width={{ base: '100%', lg: '50%' }}
        paddingLeft="22px"
        paddingRight="22px"
        paddingTop="145px"
        backgroundColor="neutral.50"
        my="auto"
      >
        <CustomFade style={{ width: '100%' }}>
          <Box marginX="auto" width={{ base: '100%', md: '90vw', lg: '483px' }}>
            <Flex flexDirection="column" gap="16px" marginBottom="40px">
              <Heading variant={{ base: 'headlineTwo', lg: 'headlineOne' }} fontWeight="bold">
                {header}
              </Heading>
              <Text color="neutral.500">{subHeader}</Text>
            </Flex>
            {children}
          </Box>
          <HStack spacing="4" margin="32px 0 32px" justifyContent="center">
            <Divider flex="1" borderColor="neutral.300" />
            <Text as="span" padding="2">
              {subText}
            </Text>
            <Divider flex="1" borderColor="neutral.300" />
          </HStack>
          <HStack>
            <SocialMediaIcons icons={iconsList} />
          </HStack>
          <Text align="center" marginTop="32px">
            {textBottom} {''}
            <Link as={ReactRouterLink} to={`../${linkTo}`} fontWeight="bold" color="primary.500">
              {linkBottom}
            </Link>
          </Text>
        </CustomFade>
      </Flex>
      <Flex
        width={{ base: '100%', lg: '50%' }}
        paddingTop="145px"
        paddingLeft="22px"
        paddingRight="22px"
        paddingBottom="75px"
        marginTop={{ base: '40px', lg: '0px' }}
        backgroundColor="neutral.100"
        alignItems="center"
        justifyContent="center"
        minH={{ base: 'auto', lg: '100vh' }}
      >
        <CustomFade style={{ width: '100%' }}>
          <Image margin="auto" width="100%" maxW="416.34px" src={coverImgSrc} alt="Cover" loading="lazy" backgroundColor="neutral.100" />
        </CustomFade>
      </Flex>
    </Flex>
    <Box width="100%" maxWidth={{ sm: 'none', lg: '80vw' }} padding={{ base: '0 16px', lg: '0' }} margin="0 auto">
      <Footer />
    </Box>
  </>
);
