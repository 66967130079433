import { useQuery } from '@tanstack/react-query';
import { axios } from '../../../lib/axios';
import { UserResponse } from '../types';
import { authQueryKeys } from '../types/queryKeyFactory';

export const getUser = (): Promise<UserResponse> => {
  return axios.get('/auth');
};

export const useGetUser = () =>
  useQuery({
    queryKey: authQueryKeys.user,
    queryFn: () => getUser(),
    refetchOnWindowFocus: true
  });
