import common from './bm/common.json';
import auth from './bm/auth.json';
import sidebar from './bm/sidebar.json';
import cta from './bm/cta.json';
import overview from './bm/overview.json';
import application from './bm/application.json';
import settings from './bm/settings.json';
import verification from './bm/verification.json';
import error from './bm/error.json';
import loans from './bm/loans.json';
import form from './bm/form.json';
import finance from './bm/finance.json';

const bm = {
  common,
  auth,
  sidebar,
  cta,
  overview,
  application,
  settings,
  verification,
  error,
  loans,
  form,
  finance
};

export default bm;
