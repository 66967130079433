// TODO : need to control footer based on the pages width dynamically
import { Flex, Text, Link, Image } from '@chakra-ui/react';

const TODAY = new Date();
const privacyPolicyUrl = `https://ilending.snappymob.dev/privacy-policy/`;

export const Footer = () => {
  return (
    <Flex direction={['column-reverse', 'row']} pt={{ base: '32px', lg: '80px' }} pb="40px" justifyContent="space-between" gap="16px">
      <Text variant="caption" color="neutral.500" display="flex" alignItems="center" gap="8px" margin={{ base: '0 auto', sm: '0' }}>
        <Image w="16px" h="16px" src="/assets/images/icons/favicon-32x32.png" /> Copyright © Alibaba Sdn Bhd. All rights reserved
      </Text>

      <Flex gap={{ base: '8px', lg: '40px' }} justifyContent="center">
        <Link href={privacyPolicyUrl} isExternal>
          <Text variant="caption" color="neutral.500">
            Privacy Policy
          </Text>
        </Link>
        <Text variant="caption" color="neutral.500" display={{ base: 'block', md: 'none' }}>
          |
        </Text>
        <Link href={privacyPolicyUrl} isExternal variant="caption" color="neutral.500">
          <Text variant="caption" color="neutral.500">
            Terms of Use
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};
