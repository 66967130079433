import { Box, Button, Card, CardBody, Flex, Heading, Text } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

type DefaultCardProps = {
  header: string;
  body: string;
  button: boolean;
  buttonText?: string;
};

export const DefaultCard = ({ header, body, button, buttonText }: DefaultCardProps) => {
  const navigate = useNavigate();

  const applyLoan = () => {
    navigate('/loans/apply');
  };
  return (
    <>
      <Box>
        <Card width="333px" borderRadius="8px" backgroundColor="neutral.0" boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05);">
          <CardBody padding={button === true ? '24px' : '24px 24px 36px'}>
            <Flex flexDirection="column">
              <Heading variant="titleTwo" fontWeight="600" color="neutral.900">
                {header}
              </Heading>
              <Text variant="bodyTwo" color="neutral.500" marginTop={button === true ? '6px' : '12px'} fontWeight={500}>
                {body}
              </Text>
              <Flex display={button === true ? 'inline-block' : 'none'}>
                <Button marginTop="20px" backgroundColor="secondary.400" variant="secondary" size="lg" onClick={applyLoan}>
                  <Text color="secondary.900">{buttonText}</Text>
                  <BsChevronRight color="#B15D00" />
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </>
  );
};
