import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { MainLayout } from '../components/Layout';
import { lazyImport } from '../utils/lazyImport';
import { Flex, Spinner } from '@chakra-ui/react';
import { NotFound } from '../features/Misc';
import { Notifications } from '../features/Notifications/routes/Notifications';

const { LoansRoutes } = lazyImport(() => import('../features/Loans'), 'LoansRoutes');

const { Dashboard } = lazyImport(() => import('../features/Misc'), 'Dashboard');
const { Settings } = lazyImport(() => import('../features/Settings'), 'Settings');
const { Finance } = lazyImport(() => import('../features/Finance'), 'Finance');
// const { Trustgate } = lazyImport(() => import('../features/Trustgate'), 'Trustgate');
const { ProfileWrapper } = lazyImport(() => import('../features/Users'), 'ProfileWrapper');

const AuthorizedApp = () => (
  <MainLayout>
    <Suspense
      fallback={
        <Flex h="100%" w="100%" alignItems="center" justifyContent="center">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="primary.500" size="xl" />
        </Flex>
      }
    >
      <Outlet />
    </Suspense>
  </MainLayout>
);

export const protectedRoutes = [
  {
    path: '/',
    element: <AuthorizedApp />,
    errorElement: <NotFound />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/loans/*', element: <LoansRoutes /> },
      { path: '/finance', element: <Finance /> },
      { path: '/settings', element: <Settings /> },
      { path: '/profile', element: <ProfileWrapper /> },
      // { path: '/trustgate', element: <Trustgate /> },
      { path: '/notifications', element: <Notifications /> },
      { path: '*', element: <Navigate to="/" /> }
    ]
  }
];
