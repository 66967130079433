import { Button, Image, Modal, ModalBody, ModalContent, ModalOverlay, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontFamily, BusinessInformationItemsDeleteType } from '../../../../utils/constants';

type props = {
  imgSrc: string;
  draftName: string;
  modalOpen: boolean;
  isCentered?: boolean;
  draftSubHeading: string;
  handleDraftDelete: () => void;
  closeOnOverlayClick?: boolean;
  setModalOpen: (toggleOption: boolean) => void;
};

export const DraftConfirmationModal = ({
  imgSrc,
  draftName,
  modalOpen,
  setModalOpen,
  draftSubHeading,
  handleDraftDelete,
  isCentered = true,
  closeOnOverlayClick = true
}: props) => {
  const { t } = useTranslation('common');

  return (
    <Modal onClose={() => setModalOpen(false)} isOpen={modalOpen} closeOnOverlayClick={closeOnOverlayClick} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent mx="20px">
        <ModalBody px="32px" py="20px">
          <Flex flexDir="column" justifyContent="center" alignItems="center" gap="28px">
            <Image src={imgSrc} />
            <Heading variant={{ base: 'titleTwo', lg: 'titleOne' }} textAlign="center" fontFamily={FontFamily.INTER} fontWeight={600}>
              {draftName}
            </Heading>
            <Text variant="caption" color="neutral.800">
              {draftSubHeading}
            </Text>
          </Flex>
          <Flex flexDir="column" justifyContent="center" alignItems="center" width="100%" mt="24px" mx="auto">
            <Button
              width="100%"
              minH="48px"
              type="button"
              fontWeight="600"
              variant="primary"
              onClick={handleDraftDelete}
              fontFamily={FontFamily.INTER}
              backgroundColor={'primary.500'}
              _hover={{ backgroundColor: 'primary.600' }}
              _active={{ backgroundColor: 'primary.700' }}
            >
              <Text variant="largeSubtitle" fontWeight={600}>
                {t('Delete')}
              </Text>
            </Button>
            <Button variant="transparent" minH="48px" width="100%" onClick={() => setModalOpen(false)} color="primary.500">
              <Text variant="largeSubtitle" fontWeight={600}>
                {t('Cancel')}
              </Text>
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
