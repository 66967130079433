import common from './en/common.json';
import auth from './en/auth.json';
import sidebar from './en/sidebar.json';
import cta from './en/cta.json';
import overview from './en/overview.json';
import application from './en/application.json';
import settings from './en/settings.json';
import verification from './en/verification.json';
import error from './en/error.json';
import loans from './en/loans.json';
import form from './en/form.json';
import finance from './en/finance.json';

const en = {
  common,
  auth,
  sidebar,
  cta,
  overview,
  application,
  settings,
  verification,
  error,
  loans,
  form,
  finance
};

export default en;
