import { Flex, Spinner } from '@chakra-ui/react';
import { createContext, useContext } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useGetUser } from '../features/Auth/api/getUser';
import { UserResponse } from '../features/Auth/types';
import { NotFound } from '../features/Misc';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const AuthContext = createContext<
  | {
      user: UserResponse;
    }
  | undefined
>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be within AuthProvider');
  }

  return context;
};

export const AppRoutes = () => {
  const { data, isLoading } = useGetUser();

  if (isLoading) {
    return (
      <Flex h="100vh" w="full" alignItems="center" justifyContent="center">
        <Spinner thickness="4px" speed="0.65s" color="primary.500" size="xl" />
      </Flex>
    );
  }

  if (!data) return <NotFound />;

  const auth = data.status === 'active' ? true : false;

  const routes = auth ? protectedRoutes : publicRoutes;

  const router = createBrowserRouter([...routes]);

  return (
    <AuthContext.Provider
      value={{
        user: data
      }}
    >
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
};
