import { Flex, Divider, Image, VStack, Link, useDisclosure, Button, Heading, Box } from '@chakra-ui/react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../features/Auth/api/logout';
import { ConfirmationDialog } from '../Elements/ConfirmationDialog';
import { ApplicationIcon, FinanceIcon, OverviewIcon, SettingsIcon, PlusIcon } from './NavIcons';
import { FontFamily } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { AuthUser, UserResponse } from '../../features/Auth';
import { useAuthContext } from '../../routes';
import { useGetProfileDetails } from '../../features/Users/api/getProfileDetails';
import { useLoanApplicationFormStore } from '../../features/Loans/stores/loanApplicationForm';
import { useState } from 'react';
import { DraftLoan } from '../../features/Loans/shared/types';
import { useGetDraftsQuery } from '../../features/Loans/api/getDrafts';
import { ApplyLoanConfirmationModal } from '../../features/Loans/components/Layout/ApplyLoanConfirmationModal';

type SidebarNavigationItem = {
  name: string;
  to: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

type UserNavigationItem = {
  name: string;
  to: string;
  icon: string;
  onClick?: () => void;
};

const SidebarNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['sidebar', 'cta']);
  const { leaveLoanModalOpen, setLeaveLoanModalOpen, applyLoanOpen, setApplyLoanOpen, setNavigateLoanModal, navigateLoanModal } =
    useLoanApplicationFormStore();

  const NAVIGATION_LIST: SidebarNavigationItem[] = [
    { name: t('Overview'), to: '/', icon: OverviewIcon },
    { name: t('Finance'), to: '/finance', icon: FinanceIcon },
    { name: t('Applications'), to: '/loans', icon: ApplicationIcon },
    { name: t('Settings'), to: '/settings', icon: SettingsIcon }
  ];

  const handleLinkClick = (to: string) => {
    if (applyLoanOpen) {
      setNavigateLoanModal(to);
      setLeaveLoanModalOpen(true);
    } else {
      setNavigateLoanModal(to);
      navigate(to);
    }
  };

  const isActiveLink = (to: string) => {
    return location.pathname === to;
  };

  return (
    <>
      {NAVIGATION_LIST.map((item, index) => (
        <Box
          // as={NavLink}
          // to={item.to}
          key={item.name}
          cursor="pointer"
          // end={index === 0}
          onClick={() => handleLinkClick(item.to)}
          _hover={{ opacity: '0.8', textDecoration: 'none' }}
          // _activeLink={{ color: 'primary.500', '.activeBorder': { display: 'block' } }}
          sx={isActiveLink(item.to) ? { color: 'primary.500', '.activeBorder': { display: 'block' } } : {}}
        >
          <Flex align="center" gap="3" px="30px" pos="relative">
            <Box
              display={isActiveLink(item.to) ? 'block' : 'none'}
              borderRadius="0px 5px 5px 0px"
              className="activeBorder"
              w="4px"
              h="30px"
              left="0"
              pos="absolute"
              bg="primary.500"
            />
            <Box as={item.icon} />
            <Heading variant="titleTwo" color="neutral.800" fontWeight="600">
              {item.name}
            </Heading>
          </Flex>
        </Box>
      ))}
    </>
  );
};

const BottomSidebarNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');
  const { leaveLoanModalOpen, setLeaveLoanModalOpen, applyLoanOpen, setApplyLoanOpen, setNavigateLoanModal, navigateLoanModal } =
    useLoanApplicationFormStore();
  const { data: draftLoans } = useGetDraftsQuery();

  const [draftForModal, setDraftForModal] = useState<DraftLoan>();
  const [showDraftLoanModalOpen, setShowDraftLoanModalOpen] = useState<boolean>(false);

  const BOTTOM_NAVIGATION_LIST: SidebarNavigationItem[] = [
    { name: t('Overview'), to: '/', icon: OverviewIcon },
    { name: t('Finance'), to: '/finance', icon: FinanceIcon },
    { name: t('Apply Loan'), to: '/loans/apply', icon: PlusIcon },
    { name: t('Application'), to: '/loans', icon: ApplicationIcon },
    { name: t('Settings'), to: '/settings', icon: SettingsIcon }
  ];

  const handleLinkClick = (to: string, index: number) => {
    if (index === 2) {
      if (draftLoans?.length) {
        setShowDraftLoanModalOpen(true);
        setDraftForModal(draftLoans[draftLoans.length - 1]);
      } else {
        navigate(to);
      }
      return;
    }

    if (applyLoanOpen) {
      setNavigateLoanModal(to);
      setLeaveLoanModalOpen(true);
    } else {
      setNavigateLoanModal(to);
      navigate(to);
    }
  };

  const isActiveLink = (to: string) => {
    return location.pathname === to;
  };

  return (
    <>
      {BOTTOM_NAVIGATION_LIST.map((item, index) => (
        <Box
          // as={NavLink}
          // to={item.to}
          key={item.name}
          onClick={() => handleLinkClick(item.to, index)}
          _hover={{ opacity: '0.8', textDecoration: 'none' }}
          // _activeLink={{ color: 'primary.500', '.label': { color: 'neutral.800' } }}
          flex={1}
          height="60px"
          display="flex"
          cursor="pointer"
          color="neutral.350"
          // end={true}
          sx={isActiveLink(item.to) ? { color: 'primary.500', '.activeBorder': { display: 'block' } } : {}}
        >
          <Flex direction="column" align="center" gap="0" px="10px" flex={1}>
            <Box flex={1} />
            <Box as={item.icon} />
            <Box flex={1} />
            <Heading variant="titleFour" color="neutral.350" fontWeight="600" className="label" noOfLines={1}>
              {item.name}
            </Heading>
          </Flex>
        </Box>
      ))}
      <ApplyLoanConfirmationModal
        draft={draftForModal}
        onClose={t(`Start new application`, { ns: 'cta' })}
        handleDelete={() => navigate('/')}
        modalOpen={showDraftLoanModalOpen}
        setModalOpen={setShowDraftLoanModalOpen}
        draftName={t(`You have draft application`, { ns: 'cta' })}
        draftSubHeading={t('You are few steps away from completing your application. What would you like to do?', {ns: 'cta'})}
      />
    </>
  );
};

const UserNavigation = () => {
  const navigate = useNavigate();
  const { data: profileData } = useGetProfileDetails();
  const { user } = useAuthContext();
  const { leaveLoanModalOpen, setLeaveLoanModalOpen, applyLoanOpen, setApplyLoanOpen, setNavigateLoanModal } =
    useLoanApplicationFormStore();

  const navigation = [
    {
      name: `${profileData && profileData?.Data?.MainApplicant?.FirstName ? profileData.Data.MainApplicant.FirstName : user.firstName} ${
        profileData && profileData?.Data?.MainApplicant?.LastName ? profileData.Data.MainApplicant.LastName : user.lastName
      }`,
      to: '/profile',
      icon: 'ic_new-user-default.svg'
    }
  ].filter(Boolean) as UserNavigationItem[];

  const handleLinkClick = (to: string) => {
    if (applyLoanOpen) {
      setNavigateLoanModal(to);
      setLeaveLoanModalOpen(true);
    } else {
      setNavigateLoanModal(to);
      navigate(to);
    }
  };

  const isActiveLink = (to: string) => {
    return location.pathname === to;
  };

  return (
    <>
      {navigation.map((item, index) => (
        <Box
          // as={NavLink}
          // to={item.to}
          key={item.name}
          cursor="pointer"
          // end={index === 0}
          onClick={() => handleLinkClick(item.to)}
          _hover={{ opacity: '0.8', textDecoration: 'none' }}
          // _activeLink={{ color: 'green', fontWeight: 'bold', '.activeBorder': { display: 'block' } }}
          sx={isActiveLink(item.to) ? { color: 'primary.500', '.activeBorder': { display: 'block' } } : {}}
        >
          <Flex mt="4" align="center" gap="3">
            <Box
              display={isActiveLink(item.to) ? 'block' : 'none'}
              borderRadius="0px 5px 5px 0px"
              className="activeBorder"
              w="4px"
              h="30px"
              left="0"
              pos="absolute"
              bg="primary.500"
              cursor="pointer"
            />
            <Image src={`/assets/images/icons/${item.icon}`} />
            <Heading variant="titleTwo">{item.name}</Heading>
          </Flex>
        </Box>
      ))}
    </>
  );
};

export const Sidebar = () => {
  const mutation = useLogoutMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('sidebar');

  return (
    <>
      <Flex
        display={{ base: 'none', lg: 'flex' }}
        pos="sticky"
        zIndex="2"
        top="26px"
        left="27px"
        height="95vh"
        filter="drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));"
        borderRadius="16px"
        minW="254px"
        backgroundColor="neutral.0"
      >
        <Flex flexDir="column" justifyContent="space-between" w="100%" overflow="auto">
          <Flex flexDir="column" w="100%" alignItems="flex-start">
            <Link as={NavLink} to="/home" p="30px">
              <Flex align="center">
                <Image width="168px" height="45px" objectFit="cover" src="/assets/images/general/nav_logo.svg" alt="logo" />
              </Flex>
            </Link>
            <VStack mt="5" mb="10" align="left" spacing="10">
              <SidebarNavigation />
            </VStack>
          </Flex>
          <Flex paddingX="20px" flexDir="column" w="100%" alignItems="flex-start">
            <Divider display="flex" />
            <Flex flexDirection="column" gap="30px" paddingLeft="12px" paddingBottom="52px">
              <UserNavigation />
              <Link onClick={onOpen} _hover={{ opacity: '0.8', textDecoration: 'none' }}>
                <Flex align="center" gap="3">
                  <Image src={`/assets/images/icons/ic_logout.svg`} />
                  <Heading fontSize="20px" fontWeight="600" lineHeight="120%" color="neutral.500">
                    {t('Log out')}
                  </Heading>
                </Flex>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        pos="fixed"
        justify="space-between"
        align="flex-end"
        bottom="0"
        pb="25px"
        zIndex={999}
        background="white"
        width="100%"
        boxShadow="0px -1px 8px rgba(0, 0, 0, 0.1), 0px -1px 8px rgba(0, 0, 0, 0.06)"
        display={{ base: 'flex', lg: 'none' }}
      >
        <BottomSidebarNavigation />
      </Flex>
      <ConfirmationDialog
        icon={<Image src="/assets/images/icons/ic_logout-popup.svg" />}
        title={t('Would you like to log out?')}
        isOpen={isOpen}
        onClose={onClose}
        closeButtonText={t('Stay')}
        confirmButton={
          <Button
            isLoading={mutation.isLoading}
            width="100%"
            variant="primary"
            type="button"
            onClick={() => mutation.mutate()}
            fontFamily={FontFamily.INTER}
            fontWeight="600"
            fontSize="20px"
          >
            {t('Log out')}
          </Button>
        }
      />
    </>
  );
};
