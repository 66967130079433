import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useCustomToast from '../../../hooks/useCustomToast';
import { axios } from '../../../lib/axios';
import { GetNotificationsQueryParams, NotificationResponse } from '../types';
import { useTranslation } from 'react-i18next';

export const getNotifications = async ({ page, limit }: GetNotificationsQueryParams): Promise<NotificationResponse[]> => {
  return await axios.get('/users/notifications', {
    params: {
      page,
      limit
    }
  });
};

export const useGetNotifications = ({ page = 1, limit = 10 }: GetNotificationsQueryParams) => {
  const { t } = useTranslation('form');
  const { addCustomToast } = useCustomToast();

  // return useInfiniteQuery({
  //   enabled: false,
  //   queryKey: ['notifications'],
  //   queryFn: () => getNotifications({ page, limit }),
  //   getNextPageParam: (lastPage) => {
  //     if (lastPage.length === 0) return undefined;
  //     else return page + 1;
  //   },
  //   onError: (err) => {
  //     if (err instanceof AxiosError) {
  //       console.log(err);
  //     }
  //     addCustomToast({
  //       title: t('Something went wrong'),
  //       status: 'error'
  //     });
  //   }
  // });

  return useQuery({
    enabled: true,
    queryKey: ['notifications'],
    queryFn: () => getNotifications({ page, limit })
  });
};

export const markAllNotificationsAsRead = async (): Promise<void> => {
  return await axios.post('/users/read-all-notifications');
};

export const useMarkAllNotificationsAsReadMutation = () => {
  const { t } = useTranslation('form');
  const queryClient = useQueryClient();

  const { addCustomToast } = useCustomToast();

  return useMutation({
    mutationFn: () => markAllNotificationsAsRead(),
    onError: (err: any) => {
      if (err instanceof AxiosError) {
        console.log(err);
      }
      addCustomToast({
        title: t('Something went wrong'),
        status: 'error'
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['notifications'] });
    }
  });
};

export const markSingleNotificationAsRead = async (notificationId: string): Promise<void> => {
  return await axios.post(`/users/${notificationId}/read-notification`);
};

export const useMarkSingleNotificationAsReadMutation = () => {
  const { t } = useTranslation('form');
  const queryClient = useQueryClient();

  const { addCustomToast } = useCustomToast();

  return useMutation({
    mutationFn: (notificationId: string) => markSingleNotificationAsRead(notificationId),
    onError: (err: any) => {
      if (err instanceof AxiosError) {
        console.log(err);
      }
      addCustomToast({
        title: t('Something went wrong'),
        status: 'error'
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['notifications'] });
    }
  });
};
