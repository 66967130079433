import { Box } from '@chakra-ui/react';
import { AuthNavbar } from './AuthNavbar';

type AuthLayoutProps = {
  children: React.ReactNode;
};

export const AuthLayout = ({ children }: AuthLayoutProps) => (
  <Box height="100vh" flexDirection="column" display="flex">
    <AuthNavbar />
    {children}
  </Box>
);
