import Axios, { AxiosRequestConfig } from 'axios';
import { redirect } from 'react-router-dom';

function authRequestInterceptor(config: AxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = 'application/json';
  }
  return config;
}

export const axios = Axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const statusCode = error.response.status;

    if (statusCode === 403) {
      return redirect('/auth/login');
    }

    return Promise.reject(error);
  }
);
