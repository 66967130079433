import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../lib/axios';
import { authQueryKeys } from '../types/queryKeyFactory';

export const logout = () => {
  return axios.delete('/auth');
};

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: logout,
    onMutate: async () => {
      await queryClient.cancelQueries(authQueryKeys.user);

      const previousUser = queryClient.getQueryData(authQueryKeys.user);

      return { previousUser };
    },
    onError: (err, _, context) => {
      if (context?.previousUser) {
        queryClient.setQueryData(authQueryKeys.user, context.previousUser);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: authQueryKeys.user });
    }
  });
};
