import { Flex, Spinner } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthLayout } from '../features/Auth/components/Layout/';
import { NotFound } from '../features/Misc';
import { lazyImport } from '../utils/lazyImport';

const { AuthRoutes } = lazyImport(() => import('../features/Auth'), 'AuthRoutes');

const UnauthorizedApp = () => (
  <AuthLayout>
    <Suspense
      fallback={
        <Flex h="full" w="full" alignItems="center" justifyContent="center">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="primary.500" size="xl" />
        </Flex>
      }
    >
      <Outlet />
    </Suspense>
  </AuthLayout>
);

export const publicRoutes = [
  {
    path: '/',
    element: <UnauthorizedApp />,
    errorElement: <NotFound />,
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      { path: '/auth/*', element: <AuthRoutes /> },
      { path: '*', element: <Navigate to="/auth/login" /> }
    ]
  }
];
