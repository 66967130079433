import { motion } from 'framer-motion';

export const animations = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 }
};

type CustomFadeProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const CustomFade = ({ children, style }: CustomFadeProps) => {
  return (
    <motion.div style={style} variants={animations} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.25 }}>
      {children}
    </motion.div>
  );
};
