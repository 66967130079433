import { Colors } from '@chakra-ui/react';

export const globalColors: Colors = {
  colors: {
    primary: {
      50: '#f4faf9',
      100: '#daf4f0',
      200: '#91d5c9',
      300: '#61c0af',
      400: '#30ab95',
      500: '#00967b',
      600: '#007862',
      700: '#005a4a',
      800: '#003c31',
      900: '#001a19'
    },
    secondary: {
      50: '#fff9f3',
      100: '#fff3e7',
      200: '#ffe8ce',
      300: '#ffdcb6',
      400: '#ffd19d',
      500: '#ffc585',
      600: '#f9b56a',
      700: '#f3a550',
      800: '#ed9635',
      900: '#b15d00'
    },
    neutral: {
      0: '#ffffff',
      50: '#f8f9fa',
      100: '#f4f5f6',
      200: '#eaecee',
      250: '#dadce0',
      300: '#bdc1c7',
      350: '#a5aab4',
      400: '#898e99',
      500: '#6b7280',
      600: '#565b66',
      700: '#40444d',
      800: '#15171a',
      900: '#000000'
    },
    info: {
      50: '#eaf3fb',
      100: '#d4e7f8',
      200: '#a9cef1',
      300: '#7Fb6e9',
      400: '#549de2',
      500: '#2985db',
      600: '#216aaf',
      700: '#195083',
      800: '#103558',
      900: '#081b2c'
    },
    positive: {
      50: '#ebfbf3',
      100: '#d8f7e7',
      200: '#b0f0cf',
      300: '#89e8b7',
      400: '#61e19f',
      500: '#3ad987',
      600: '#2faf6d',
      700: '#248553',
      800: '#185c39',
      900: '#0d321f'
    },
    warning: {
      50: '#fff9e5',
      100: '#fff4cc',
      200: '#ffe999',
      300: '#ffde66',
      400: '#ffd333',
      500: '#ffc800',
      600: '#cfa200',
      700: '#9f7d00',
      800: '#6f5700',
      900: '#3f3200'
    },
    negative: {
      50: '#fcecec',
      100: '#fad9d9',
      200: '#f5b3b3',
      300: '#f08d8d',
      400: '#eb6767',
      500: '#e64141',
      600: '#c13535',
      700: '#9c2929',
      800: '#761e1e',
      900: '#511212'
    }
  }
};
