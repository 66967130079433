import { Flex, Image, Link } from '@chakra-ui/react';
import { CTASection } from './CTASection';
import { NavLink } from 'react-router-dom';
import { useGetDraftsQuery } from '../../Loans/api/getDrafts';

export const MobileNavbar = () => {
  const { data: draftLoans } = useGetDraftsQuery();

  return (
    <>
      <Flex pos="fixed" bg="neutral.50" p="14px 20px" justifyContent="space-between" w="100%">
        <Link as={NavLink} to="/">
          <Image width="136px" height="36.35px" objectFit="cover" src="/assets/images/general/nav_logo.svg" alt="logo" />
        </Link>
        <CTASection draftLoans={draftLoans} />
      </Flex>
    </>
  );
};
