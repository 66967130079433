import { defineStyleConfig } from '@chakra-ui/react';
import { PaginationClassNames } from '../../features/Loans/components/Pagination/types';

export const CustomPagination = defineStyleConfig({
  baseStyle: {
    [`.${PaginationClassNames.CONTAINER}`]: {
      display: 'flex',
      gap: '8px'
    },
    [`.${PaginationClassNames.NEXT_AND_PREV_LABEL}`]: {
      display: 'none'
    },
    [`.${PaginationClassNames.LIST_ITEM}`]: {
      borderRadius: '6px',
      color: 'neutral.300',
      display: 'flex'
    },
    [`.${PaginationClassNames.ACTIVE_LIST_ITEM}`]: {
      color: 'primary.500',
      bg: 'rgba(0, 150, 123, 0.08)'
    },
    [`.${PaginationClassNames.LIST_ITEM_LINK}`]: {
      fontSize: '18px',
      lineHeight: '120%',
      h: '36px',
      minW: '36px',
      color: 'inherit',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
});
