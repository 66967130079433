import { useQuery } from '@tanstack/react-query';
import { axios } from '../../../lib/axios';
import { authQueryKeys } from '../../Auth/types/queryKeyFactory';

export const getProfileDetails = (): Promise<any> => {
  return axios.get('/me/details');
}

export const useGetProfileDetails = () =>
  useQuery({
    queryKey: authQueryKeys.profile,
    queryFn: () => getProfileDetails(),
  });
