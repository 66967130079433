import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import { Suspense } from 'react';
import { Box, Button, ChakraProvider, Flex, Heading, Spinner, VStack } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import theme from '../theme';
import { DefaultOptions, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const ErrorFallback = () => {
  return (
    <Box minH="100vh" display="flex" justifyContent="center" alignItems="center">
      <Flex flexDir="column" alignItems="center">
        <Heading size="lg">Oops, Something went wrong</Heading>
        <VStack spacing={4} mt={4}>
          <Button size="md" variant="primary" onClick={() => window.location.assign(window.location.origin)}>
            Refresh
          </Button>
        </VStack>
      </Flex>
    </Box>
  );
};

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false
  }
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const queryClient = new QueryClient({ defaultOptions: queryConfig });

  return (
    <ChakraProvider theme={theme}>
      <Suspense
        fallback={
          <Flex h="full" w="full" alignItems="center" justifyContent="center">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Flex>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            {children}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </ErrorBoundary>
      </Suspense>
    </ChakraProvider>
  );
};
