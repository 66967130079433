import { Box, Card, CardHeader, CardBody, Flex, Heading, Link, Text, Progress, HStack, Button } from '@chakra-ui/react';
import { useCurrentDateTime } from '../../../hooks/useCurrentDateTime';
import { ApplicationCard } from '../components/Cards/ApplicationCard';
import { DefaultCard } from '../components/Cards/DefaultCard';
import { CTASection } from '../components/CTASection';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { UserResponse } from '../../Auth';
import { FontFamily } from '../../../utils/constants';
import { useGetDraftsQuery } from '../../Loans/api/getDrafts';
import { useGetAwaitingLoansQuery } from '../../Loans/api/getAwaitingLoans';
import { useGetLoanSummaryQuery } from '../../Loans/api/getLoanSummary';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { currencyFormatter } from '../../../utils/helperFunctions';
import { ActiveLoansCard } from '../components/Cards/ActiveLoansCard';
import { useGetApprovedLoansQuery } from '../../Loans/api/getApprovedLoans';
import { useGetActiveLoansQuery } from '../../Loans/api/getActiveLoans';
import { useGetProfileDetails } from '../../Users/api/getProfileDetails';
import { useAuthContext } from '../../../routes';

type SummaryCardItemProps = {
  header: string;
  count: number;
  color: string;
};

const SummaryCardItem = ({ items }: { items: SummaryCardItemProps[] }) => {
  const { t } = useTranslation(['overview', 'common']);

  return (
    <>
      {items.map((item, index) => (
        <Card
          key={index}
          w="225px"
          padding="24px"
          borderRadius="10"
          boxShadow="transparent"
          backgroundColor="neutral.0"
          flex="1"
          gap="12px"
          maxWidth={{ base: 'calc(50% - 8px)', md: 'none' }}
          flexBasis={{ base: '50%', md: 'inherit' }}
        >
          <CardHeader padding="0">
            <Heading variant="titleThree" textTransform="uppercase" color={item.color} fontWeight="600">
              {t(item.header, { ns: 'common' })}
            </Heading>
          </CardHeader>
          <CardBody padding="0">
            <Heading variant="displayThree" fontWeight="700" color="neutral.300">
              {item.count}
            </Heading>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export const Dashboard = () => {
  const queryClient = useQueryClient();
  const { data: profileData } = useGetProfileDetails();
  const { user } = useAuthContext();

  const { data: draftLoans } = useGetDraftsQuery();
  const { data: summary } = useGetLoanSummaryQuery();
  const { data: activeLoans } = useGetActiveLoansQuery();
  const { data: approvedLoans } = useGetApprovedLoansQuery();
  const { data: awaitingLoans } = useGetAwaitingLoansQuery();

  const { t } = useTranslation(['overview', 'common', 'application']);
  const { dateString, timeString } = useCurrentDateTime();

  const summaryItems: SummaryCardItemProps[] = useMemo(() => {
    return [
      { header: 'active', count: summary?.active || 0, color: 'info.500' },
      { header: 'approved', count: summary?.approved || 0, color: 'primary.500' },
      { header: 'pending', count: summary?.pending || 0, color: 'secondary.800' },
      { header: 'declined', count: summary?.declined || 0, color: 'negative.500' },
      { header: 'completed', count: summary?.completed || 0, color: 'info.500' }
    ];
  }, [summary]);

  return (
    <>
      <Flex flexDir="row" justifyContent="space-between" mb="42px">
        <Flex flexDir="column" alignItems="flex-start" gap="4px">
          <Heading fontSize="2xl">
            {t('Hello')}{' '}
            {profileData && profileData?.Data?.MainApplicant?.FirstName ? profileData.Data.MainApplicant.FirstName : user.firstName}{' '}
            {profileData && profileData?.Data?.MainApplicant?.LastName ? profileData.Data.MainApplicant.LastName : user.lastName}
          </Heading>
          <Text color="neutral.500">
            {dateString}, {timeString}
          </Text>
        </Flex>
        <Box display={{ base: 'none', lg: 'inherit' }}>
          <CTASection draftLoans={draftLoans} />
        </Box>
      </Flex>
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <Heading variant={{ base: 'titleOne', lg: 'headlineTwo' }} fontWeight="bold">
          {t('Application Summary')}
        </Heading>
        <Link as={NavLink} to="/loans" textAlign={{ base: 'center', md: 'inherit' }}>
          <Text variant="subtitle" fontWeight={600} fontFamily={FontFamily.INTER} color="primary.500">
            {t('View all')}
          </Text>
        </Link>
      </Flex>
      <Flex
        mt={{ base: '25.5px', lg: '24px' }}
        mb={{ base: '35px', lg: '24.5px' }}
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        gap={{ base: '16px', md: '20px' }}
      >
        <SummaryCardItem items={summaryItems} />
      </Flex>
      <Flex flexDirection="column" gap="24px">
        <Box>
          <Flex gap="8px">
            <Heading variant={{ base: 'titleOne', lg: 'headlineTwo' }} fontWeight="bold">
              {t('Awaiting Application')}
            </Heading>
            <Flex bg="white" px="2" borderRadius="8px" border="1px solid #DADCE0" alignItems="center">
              <Heading variant="titleThree">{awaitingLoans?.length || 0}</Heading>
            </Flex>
          </Flex>
          <Flex
            mt="20.5px"
            mx={{ base: '-16px', lg: '-60px' }}
            px={{ base: '16px', lg: '60px' }}
            gap="20px"
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              },
              scrollBarWidth: 'none'
            }}
            overflowX="scroll"
          >
            {!awaitingLoans || awaitingLoans.length === 0 ? (
              <DefaultCard
                header={t('Co-applicant verification')}
                body={t(
                  'Each associated shareholder/director of the company will have to go through a verification process for the application.'
                )}
                button={false}
              />
            ) : (
              awaitingLoans.map((loan) => {
                return <ApplicationCard key={loan.id} loan={loan} />;
              })
            )}
          </Flex>
        </Box>
        <Box>
          <Flex gap="8px">
            <Heading variant={{ base: 'titleOne', lg: 'headlineTwo' }} fontWeight="bold" textTransform="capitalize">
              {t('draft', { ns: 'common' })}
            </Heading>
            <Flex bg="white" px="2" borderRadius="8px" border="1px solid #DADCE0" alignItems="center">
              <Heading variant="titleThree">{draftLoans?.length || 0}</Heading>
            </Flex>
          </Flex>
          <Flex
            mt="20.5px"
            mx={{ base: '-16px', lg: '-60px' }}
            px={{ base: '16px', lg: '60px' }}
            gap="20px"
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              },
              scrollBarWidth: 'none'
            }}
            overflowX="scroll"
          >
            {!draftLoans || draftLoans.length === 0 ? (
              <DefaultCard
                header={t('Discover Financing Options', { ns: 'application' })}
                body={t('iLending provides various financing solutions tailored to your business needs.', { ns: 'application' })}
                button={true}
                buttonText={t('Find out more', { ns: 'application' }) as string}
              />
            ) : (
              draftLoans.map((loan) => {
                return <ApplicationCard key={loan.id} loan={loan} />;
              })
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex marginTop="60px">
        <Box width="105%">
          <Flex gap="8px">
            <Heading variant={{ base: 'titleOne', lg: 'headlineTwo' }} fontWeight="bold">
              {t('Transaction Overview')}
            </Heading>
            <Flex bg="white" px="2" borderRadius="8px" border="1px solid #DADCE0" alignItems="center">
              <Heading variant="titleThree">{activeLoans?.length}</Heading>
            </Flex>
          </Flex>

          <Flex
            mt="20.5px"
            mx={{ base: '-16px', lg: '-60px' }}
            px={{ base: '16px', lg: '60px' }}
            gap="20px"
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              },
              scrollBarWidth: 'none'
            }}
            overflowX="scroll"
          >
            {!activeLoans || activeLoans.length === 0 ? (
              <DefaultCard
                header={t('Track your payment at ease')}
                body={t('View the details of your overall loan transaction here.')}
                button={true}
                buttonText={t('Apply now') as string}
              />
            ) : (
              activeLoans.map((loan) => {
                return <ActiveLoansCard key={loan.id} loan={loan} />;
              })
            )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
