import * as z from 'zod';
import create from 'zustand';
import { LoanApplicationFormDTO } from '../routes/ApplyLoan';
import { LoanTermData } from '../components/LoanApplicationForm/LoanTermForm';
import { PersonalDetailsData } from '../components/LoanApplicationForm/PersonalDetailsForm';
import { addressSchema, coApplicantSchema, litigationSchema } from '../types/loanApplicationForm';
import { BusinessInformationData } from '../components/LoanApplicationForm/BusinessInformationForm';

const stepVariant = {
  1: 'loanTerm',
  2: 'businessInformation',
  3: 'personalDetails'
};

type setDataType = { step: 1; data: LoanTermData } | { step: 2; data: BusinessInformationData } | { step: 3; data: PersonalDetailsData };

type LoanApplicationFormStore = {
  loanTerm: LoanTermData | null;
  businessInformation: BusinessInformationData | null;
  personalDetails: PersonalDetailsData | null;
  setFormData: ({ step, data }: setDataType) => void;
  businessInformationSchema: any;
  setBusinessInformationSchemaData: (data: any) => void;
  certify: boolean;
  setCertify: (data: boolean) => void;
  registerMandateLoading: boolean;
  setRegisterMandateLoading: (data: boolean) => void;
  leaveLoanModalOpen: boolean;
  setLeaveLoanModalOpen: (data: boolean) => void;
  applyLoanOpen: boolean;
  setApplyLoanOpen: (data: boolean) => void;
  navigateLoanModal: string | undefined;
  setNavigateLoanModal: (data: string) => void;
  getLoanQueryLoading: boolean | undefined;
  setGetLoanQueryLoading: (data: boolean) => void;
};

export const useLoanApplicationFormStore = create<LoanApplicationFormStore>((set) => ({
  loanTerm: null,
  businessInformation: null,
  personalDetails: null,
  businessInformationSchema: null,
  certify: false,
  setCertify: (data: boolean) =>
    set((state) => ({
      ...state,
      certify: data
    })),
  setBusinessInformationSchemaData: (data) =>
    set((state) => ({
      ...state,
      businessInformationSchema: data
    })),
  setFormData: ({ step, data }) =>
    set((state) => ({
      ...state,
      [stepVariant[step]]: data
    })),
  registerMandateLoading: false,
  setRegisterMandateLoading: (data: boolean) =>
    set((state) => ({
      ...state,
      registerMandateLoading: data
    })),
  leaveLoanModalOpen: false,
  setLeaveLoanModalOpen: (data: boolean) =>
    set((state) => ({
      ...state,
      leaveLoanModalOpen: data
    })),
  applyLoanOpen: false,
  setApplyLoanOpen: (data: boolean) =>
    set((state) => ({
      ...state,
      applyLoanOpen: data
    })),
  navigateLoanModal: undefined,
  setNavigateLoanModal: (data: string) =>
    set((state) => ({
      ...state,
      navigateLoanModal: data
    })),
  getLoanQueryLoading: undefined,
  setGetLoanQueryLoading: (data: boolean) =>
    set((state) => ({
      ...state,
      getLoanQueryLoading: data
    }))
}));
