import { Box, Button, Card, CardBody, Flex, Heading, Text } from '@chakra-ui/react';
import { TbTrash } from 'react-icons/tb';
import { FontFamily } from '../../../../utils/constants';
import { LoanStatus } from '../../../Loans/shared/constants';
import { useTranslation } from 'react-i18next';
import { GenericLoan } from '../../../Loans/shared/types';
import useDateFormatter, { DateFormat } from '../../../../hooks/useDateFormatter';
import { currencyFormatter } from '../../../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';
import { useDeleteDraftMutation } from '../../../Loans/api/saveDraft';
import { useQueryClient } from '@tanstack/react-query';
import { loanKeys } from '../../../Loans/types/queryKeyFactory';
import { LoanConfirmationModal } from '../../../Loans/components/LoanApplicationForm/LoanConfirmationModal';
import { useState } from 'react';
import { DraftConfirmationModal } from '../../../Loans/components/LoanApplicationForm/DraftConfirmationModal';

type ApplicationCardProps = {
  loan: GenericLoan;
  refetchDrafts?: any;
};

export const ApplicationCard = ({ loan, refetchDrafts }: ApplicationCardProps) => {
  const { t } = useTranslation(['overview', 'common']);
  const { formatDate } = useDateFormatter();
  const draftDeleteMutation = useDeleteDraftMutation();
  const [deleteDraftModalOpen, setDeleteDraftModalOpen] = useState<boolean>(false);

  const { id, creditProductName, repaymentTotalDue, updatedDate, repaymentDueDate, status, turnkeyId, amount, draftId } = loan;

  const navigate = useNavigate();

  const getBackgroundImage = (): string => {
    switch (status) {
      case LoanStatus.DRAFT:
        return 'draft';
      case LoanStatus.AWAITING:
        return 'awaiting';
      case LoanStatus.APPROVED:
      case LoanStatus.PENDING:
      case LoanStatus.COMPLETED:
      case LoanStatus.ACTIVE:
      case LoanStatus.DECLINED:
        switch (creditProductName) {
          case 'Invoice Financing':
            return 'invoicing-financing';
          case 'SMEs & MSMEs Digital Financing':
            return 'SMES-digital-financing';
          case 'Supply Chain Financing':
            return 'supply-chain-financing';
          default:
            return 'draft';
        }
    }
  };

  const deleteDraftHandler = () => {
    draftDeleteMutation.mutate(loan.id);
  };

  const handleDeleteDraftLitigation = () => {
    setDeleteDraftModalOpen(true);
  };

  return (
    <>
      <Box>
        <Card
          minHeight="210px"
          width="333px"
          borderRadius="12px"
          backgroundRepeat="no-repeat"
          backgroundSize="100% 100%"
          backgroundImage={`/assets/images/general/img_${getBackgroundImage()}-application.jpg`}
        >
          <CardBody>
            <Box>
              <Text
                background="rgba(0, 0, 0, 0.14)"
                padding="6px 12px"
                display="inline-block"
                borderRadius="6px"
                color="neutral.0"
                fontFamily={FontFamily.INTER}
                variant="overlineTwo"
                textTransform="uppercase"
                fontWeight="bold"
              >
                {t(creditProductName, { ns: 'common' })} {draftId ? `#D-${draftId}` : `#${turnkeyId}`}
              </Text>
              <Box margin="20px 0 16px">
                <Flex color="neutral.0" alignItems="center" gap="5px">
                  <Heading variant="titleOne" textTransform="uppercase" fontWeight="bold">
                    {currencyFormatter.format(amount)}
                  </Heading>
                  <Text variant="overlineOne">/ {t('month')}</Text>
                </Flex>
                <Text color="neutral.0">
                  {status === LoanStatus.DRAFT
                    ? t('Last edit') + ' ' + `${formatDate({ date: updatedDate, format: DateFormat.DDMMMYYYY })}`
                    : t('Due on') + ' ' + `${formatDate({ date: repaymentDueDate, format: DateFormat.DDMMMYYYY })}`}
                </Text>
              </Box>
              <Flex>
                <Button
                  variant="ghostOutline"
                  onClick={() => {
                    const url = status === LoanStatus.DRAFT ? `/loans/apply?loanId=${loan.id}` : `/loans/${loan.id}`;
                    navigate(url);
                  }}
                >
                  <Text color="neutral.0">{status === LoanStatus.DRAFT ? t('Resume Application') : t('See Details')}</Text>
                </Button>
                {status === LoanStatus.DRAFT && (
                  <Button marginLeft="auto" backgroundColor="transparent" variant="transparent" onClick={handleDeleteDraftLitigation}>
                    <TbTrash color="#fff" size="28" />
                  </Button>
                )}
              </Flex>
            </Box>
          </CardBody>
        </Card>
        <DraftConfirmationModal
          modalOpen={deleteDraftModalOpen}
          setModalOpen={setDeleteDraftModalOpen}
          handleDraftDelete={deleteDraftHandler}
          imgSrc={'/assets/images/icons/ic_delete-popup.svg'}
          draftName={
            t(`Do you really want to delete draft `) + t(creditProductName, { ns: 'common' }) + ` ${draftId ? `#D-${draftId}` : turnkeyId}?`
          }
          draftSubHeading={t('Draft application once deleted cannot be retrieved.')}
        />
      </Box>
    </>
  );
};
