import { AddIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  Button,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Heading,
  Link,
  PopoverFooter
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontFamily } from '../../../utils/constants';
import { Notification } from '../../Notifications/components/Notification';
import { useTranslation } from 'react-i18next';
import { ApplyLoanConfirmationModal } from '../../Loans/components/Layout/ApplyLoanConfirmationModal';
import { DraftLoan } from '../../Loans/shared/types';
import { useGetNotifications, useMarkAllNotificationsAsReadMutation } from '../../Notifications/api/getNotifications';
import { NotificationStatus, NotificationType } from '../../Notifications/types';
import { useLoanApplicationFormStore } from '../../Loans/stores/loanApplicationForm';
import { getNotificationDetailsTitle } from '../../Notifications/routes/Notifications';

type CTASectionProps = {
  draftLoans?: DraftLoan[];
};

export const CTASection = ({ draftLoans }: CTASectionProps) => {
  const { t } = useTranslation('cta');
  const [draftForModal, setDraftForModal] = useState<DraftLoan>();
  const [showDraftLoanModalOpen, setShowDraftLoanModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const applyLoan = () => {
    navigate('/loans/apply');
  };

  const handleLeaveLoanModel = () => {
    if (draftLoans?.length) {
      setShowDraftLoanModalOpen(true);
      setDraftForModal(draftLoans[draftLoans.length - 1]);
    } else {
      applyLoan();
    }
  };

  const [page, setPage] = useState(1);
  const { data: allNotifications } = useGetNotifications({ page });

  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    if (allNotifications && allNotifications.length > 0) {
      const hasUnreadNotification = allNotifications.some((notification) => notification.status === NotificationStatus.Unread);
      setHasNotification(hasUnreadNotification);
    }
  }, [allNotifications]);

  const readAllNotificationsMutation = useMarkAllNotificationsAsReadMutation();
  const handleReadAll = () => {
    readAllNotificationsMutation.mutate();
  };

  return (
    <>
      <Flex display={{ base: 'flex', lg: 'none' }}>
        <Link
          as={NavLink}
          to="/notifications"
          color="primary.500"
          fontFamily={FontFamily.INTER}
          fontWeight={600}
          fontSize="18px"
          lineHeight="120%"
        >
          <Button
            backgroundColor="neutral.50"
            border="none"
            borderColor="transparent"
            _hover={{ bg: 'neutral.100' }}
            _focus={{ bg: 'neutral.100', borderColor: 'neutral.300' }}
          >
            {hasNotification ? (
              <Image src="/assets/images/icons/ic_notification-new.svg" />
            ) : (
              <Image src="/assets/images/icons/ic_notification-default.svg" />
            )}
          </Button>
        </Link>
      </Flex>
      <HStack display={{ base: 'none', lg: 'flex' }} justifyContent="flex-end" marginBottom="26px">
        <Popover>
          <PopoverTrigger>
            <Button
              backgroundColor="neutral.0"
              _hover={{ bg: 'neutral.100' }}
              _focus={{ bg: 'neutral.100', borderColor: 'neutral.300' }}
              border="1px solid"
              borderColor="neutral.200"
              h="56px"
              w="56px"
            >
              {hasNotification ? (
                <Image src="/assets/images/icons/ic_notification-new.svg" />
              ) : (
                <Image src="/assets/images/icons/ic_notification-default.svg" />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent py="16px" px="20px" w="407px">
            <PopoverHeader paddingInline={0} pt="16px" pb="8px" borderBottom="1px solid" borderColor="neutral.200">
              <HStack spacing="16px" justifyContent="space-between" alignItems="center">
                <Heading variant="titleOne" fontWeight={700}>
                  {t('Notifications')}
                </Heading>
                <Link
                  href="#"
                  color="primary.500"
                  fontFamily={FontFamily.INTER}
                  fontWeight={600}
                  fontSize="18px"
                  lineHeight="120%"
                  onClick={handleReadAll}
                >
                  {t('Mark all as read')}
                </Link>
              </HStack>
            </PopoverHeader>
            <PopoverBody paddingInline={0} py={0}>
              {allNotifications &&
                allNotifications
                  .slice(0, 3)
                  .map(({ id, loanId, message, createdDate, updatedDate, status, type, loan, loanMilestone, turnkeyLoanStatus }, index) => (
                    <Notification
                      key={index}
                      id={id}
                      loanId={loanId}
                      message={message}
                      createdDate={createdDate}
                      updatedDate={updatedDate}
                      status={status}
                      type={type}
                      loanName={`${loan.creditProductName} ${loan.turnkeyId}`}
                      loanMilestone={loanMilestone}
                      turnkeyLoanStatus={turnkeyLoanStatus}
                      seeMoreText={getNotificationDetailsTitle(
                        type ? type : '',
                        loan.creditProductName ? loan.creditProductName : '',
                        loan.turnkeyId ? loan.turnkeyId : ''
                      )}
                    />
                  ))}
            </PopoverBody>
            <PopoverFooter p={0} mt="20px" mb="8px" border={0} display="flex" justifyContent="center">
              <Link
                as={NavLink}
                to="/notifications"
                color="primary.500"
                fontFamily={FontFamily.INTER}
                fontWeight={600}
                fontSize="18px"
                lineHeight="120%"
              >
                {t('See all notifications')}
              </Link>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
        <Button leftIcon={<AddIcon />} variant="primary" size="lg" height="56px" onClick={handleLeaveLoanModel}>
          {t('Apply Loan')}
        </Button>
      </HStack>
      <ApplyLoanConfirmationModal
        draft={draftForModal}
        onClose={t(`Start new application`)}
        handleDelete={() => navigate('/')}
        modalOpen={showDraftLoanModalOpen}
        setModalOpen={setShowDraftLoanModalOpen}
        draftName={t(`You have draft application`)}
        draftSubHeading={t('You are few steps away from completing your application. What would you like to do?')}
      />
    </>
  );
};
