import { useState } from 'react';
import { Box, Flex, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../../../../i18n';
import { ChevronDownIcon } from '@chakra-ui/icons';

const LANG_LABEL: { [key: string]: string } = {
  en: 'English',
  bm: 'Bahasa Melayu'
};

export const AuthNavbar = () => {
  const [isMobile] = useState(false);
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  return (
    <Box pos="fixed" w="100vw" minH="84.9px" paddingTop="17px" zIndex="10">
      <Flex
        minH="84.9px"
        padding="20px 22px"
        borderRadius="8px"
        w={isMobile ? '75px' : '90vw'}
        flexDir="row"
        justifyContent="space-between"
        backgroundColor="neutral.0"
        margin="0 auto"
        alignItems="center"
        boxShadow="0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)"
      >
        <Image
          width="168px"
          height="auto"
          objectFit="cover"
          src="/assets/images/general/nav_logo.svg"
          alt="logo"
          cursor="pointer"
          // onClick={() => navigate('/')}
          onClick={() => window.location.href = 'https://ilending.snappymob.dev/'} // currently user will redirected to the corporate website, will update this again once, the corporate website url is changed.
        />
        <Menu>
          <MenuButton>
            {i18n.language.toUpperCase()} <ChevronDownIcon />
          </MenuButton>
          <MenuList boxShadow="0px 6px 32px rgba(0, 0, 0, 0.15)">
            {availableLanguages.map((lang) => (
              <MenuItem
                key={lang}
                onClick={() => {
                  i18n.changeLanguage(lang, (error) => {
                    if (error) return console.log('Something went wrong loading', error);
                  });
                }}
              >
                {LANG_LABEL[lang]}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};
