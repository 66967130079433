export function formatBytes(bytes: number, decimals = 2) {
  if (!Number(bytes)) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const currencyFormatter = new Intl.NumberFormat('en-MY', {
  style: 'currency',
  currency: 'MYR'
});

export function verifyMalaysianPostalCode(postalCode: string): boolean {
  try {
    if (/^\d{5}$/.test(postalCode)) {
      const validCodeRanges: Record<string, (number: number) => boolean> = {
        kualaLumpur: (number) => (number >= 50000 && number <= 60000) || number === 68100,
        putrajaya: (number) => number >= 62300 && number <= 62988,
        labuan: (number) => number >= 87000 && number <= 87033,
        selangor: (number) => (number >= 40000 && number <= 48300) || (number >= 20000 && number <= 24300),
        terengganu: (number) => number >= 20000 && number <= 24300,
        sarawak: (number) => number >= 93000 && number <= 98859,
        sabah: (number) => number >= 88000 && number <= 91309,
        kedah: (number) => number >= 5000 && number <= 9810,
        kelantan: (number) => number >= 15000 && number <= 18500,
        negeriSembilan: (number) => number >= 70000 && number <= 73509,
        pulauPinang: (number) => number >= 10000 && number <= 14400,
        johor: (number) => number >= 79000 && number <= 86900,
        melaka: (number) => number >= 75000 && number <= 78309,
        perlis: (number) => number >= 1000 && number <= 2999,
        perak: (number) => number >= 30000 && number <= 36810,
        pahang: (number) =>
          (number >= 25000 && number <= 28800) ||
          (number >= 39000 && number <= 39200) ||
          (number >= 28000 && number <= 28350) ||
          number === 49000 ||
          number === 69000
      };

      for (const state in validCodeRanges) {
        if (validCodeRanges[state](parseInt(postalCode, 10))) {
          return true;
        }
      }
    }

    return false;
  } catch (ex: unknown) {
    return false;
  }
  return false;
}

export const getKeyByValue = <T>(obj: { [key: string]: T }, value: T): string => {
  for (const key in obj) {
    if (obj[key] === value) {
      return key;
    }
  }

  return '';
};
