import { ApprovalStatus, ListingLoan, ListLoans, SubmissionStatus } from './types';

export const verificationTexts: {
  userCertifyOrderedTexts: string[];
  complianceText: string;
  consentAgreementText: string;
  eConsentOrderedTexts: string[];
  eConsentParaTexts: string[];
} = {
  userCertifyOrderedTexts: [
    'Consent to receive either emails, SMS messages of Phone calls from customer service representatives in order to verify your information.',
    'Certify that information you have supplied under this loan application is true and correct.',
    'By submitting my information, I certify that I am at least 18 years of age and agree to our Terms Conditions and Privacy Policy.',
    'Understand that we perform credit check and loan eligibility through credit bureaus and third party services.'
  ],
  complianceText:
    'We do not share your information stored in our database with any third-party services or other affiliates. We only use this information within the duration of the loan and to qualify you and offer specific promotions in the future. Throughout the process, our security measures will keep your information strictly confidential. For more details, review our complete privacy policy.',
  consentAgreementText:
    'I, the undersigned hereby give consent to (iLending) Alibaba Pay Sdn Bhd (202101012069) to obtain and/or disclose any Credit Information (as defined in the Credit Reporting Agencies Act 2010) relating to me from and/or to Experian Information Services (Malaysia) Sdn. Bhd. (“Experian”), one of the registered credit reporting agencies in Malaysia or any source deemed appropriate to verify my/our credit history and/or my/our eligibility to be provided with credit as you and/or Experian or any source deemed appropriate may deem fit as authorized or permitted under any applicable law, regulation, guidelines, regulatory requirement or directive in relation to my credit application or transaction with you for the following purposes (but not limited to):',
  eConsentOrderedTexts: [
    'Opening of account',
    'Credit evaluation',
    'Credit/account review',
    'Legal documentation and/or action consented to under a contract or facility granted',
    'Debt recovery purposes',
    'Employment evaluation',
    'Any other related matters'
  ],
  eConsentParaTexts: [
    'This consent shall remain applicable as long as I am maintaining any account/ loan/ credit / transaction with you.',
    'Where you require any of our Credit Information to be processed by any processing centre located outside Malaysia (including your Head Office), I hereby give consent to Experian to disclose my Credit Information to such locations outside Malaysia.',
    'Apart from the above, I, the undersigned do give my consent to (iLending) Alibaba Pay Sdn Bhd and Experian to process my/other personal data provided by me as per the Personal Data Protection Act 2010.'
  ]
};

export const FIXED_INTEREST_RATE = 0.015; // 1.5%

export enum LoanStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  COMPLETED = 'completed',
  DRAFT = 'draft',
  DECLINED = 'declined',
  AWAITING = 'awaiting',
  ACTIVE = 'active'
}

export enum LoanSortingColumns {
  UPDATED_DATE = 'updatedDate',
  AMOUNT = 'amount'
}

export enum SortinOrders {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum CurlecMandateResponseCode {
  AWAITING_FPX_AUTHORISATION = '-1',
  AWAITING_FPX_TERMINATION = '-2',
  NEW = '-3',
  TERMINATED = '-4',
  APPROVED = '00',
  INVALID_MERCHANT = '03',
  INVALID_SELLER_OR_ACQUIRING_BANK_CODE = '05',
  TRANSACTION_PENDING = '09',
  INVALID_TRANSACTION = '12',
  INVALID_AMOUNT = '13',
  INVALID_BUYER_ACCOUNT = '14',
  INVALID_RESPONSE = '20',
  FORMAT_ERROR = '30',
  INVALID_BANK = '31',
  NO_CREDIT_ACCOUNT = '39',
  DUPLICATE_SELLER_ORDER_NUMBER = '45',
  INVALID_SELLER_EXCHANGE_OR_SELLER = '46',
  INVALID_CURRENCY = '47',
  MAXIMUM_TRANSACTION_LIMIT_EXCEEDED = '48',
  MERCHANT_SPECIFIC_LIMIT_EXCEEDED = '49',
  INVALID_SELLER_FOR_MERCHANT_SPECIFIC_LIMIT = '50',
  INSUFFICIENT_FUNDS = '51',
  NO_BUYER_ACCOUNT_NUMBER = '53',
  TRANSACTION_NOT_PERMITTED = '57',
  TRANSACTION_TO_MERCHANT_NOT_PERMITTED = '58',
  INVALID_SERIAL_NUMBER = '70',
  TRANSACTION_NOT_FOUND = '76',
  INVALID_BUYER_NAME_OR_BUYER_ID = '77',
  DECRYPTION_FAILED = '78',
  HOST_DECLINE_WHEN_DOWN = '79',
  BUYER_CANCEL_TRANSACTION = '80',
  INVALID_TRANSACTION_MODEL = '83',
  INVALID_TRANSACTION_TYPE = '84',
  INTERNAL_ERROR_AT_BANK_SYSTEM = '85',
  DEBIT_FAILED_EXCEPTION_HANDLING = '87',
  CREDIT_FAILED_EXCEPTION_HANDLING = '88',
  TRANSACTION_NOT_RECEIVED_EXCEPTION_HANDLING = '89',
  BANK_INTERNET_BANKING_UNAVAILABLE = '90',
  INVALID_BUYER_BANK_96_SYSTEM_MALFUNCTION = '92',
  MAC_ERROR = '98',
  PENDING_AUTHORISATION_B2B = '99',
  BLOCKED_BANK = 'BB',
  TRANSACTION_CANCELLED_BY_CUSTOMER = 'BC',
  INVALID_APPLICATION_TYPE = 'DA',
  INVALID_EMAIL_FORMAT = 'DB',
  INVALID_MAXIMUM_FREQUENCY = 'DC',
  INVALID_FREQUENCY_MODE = 'DD',
  INVALID_EXPIRY_DATE = 'DE',
  INVALID_E_MANDATE_BUYER_BANK_ID = 'DF',
  INTERNAL_ERROR = 'FE',
  TRANSACTION_REJECTED_AS_NOT_IN_FPX_OPERATING_HOURS = 'OE',
  TRANSACTION_TIMEOUT = 'OF',
  INVALID_ACQUIRING_BANK_CODE = 'SB',
  INVALID_SOURCE_IP_ADDRESS_B2B2B = 'XA',
  INVALID_SELLER_EXCHANGE_IP = 'XB',
  SELLER_EXCHANGE_ENCRYPTION_ERROR = 'XC',
  INVALID_MESSAGE = 'XE',
  INVALID_NUMBER_OF_ORDERS = 'XF',
  INVALID_SELLER_EXCHANGE = 'XI',
  INVALID_FPX_TRANSACTION_MODEL = 'XM',
  TRANSACTION_REJECTED_DUE_TO_DUPLICATE_SELLER_EXCHANGE_ORDER_NUMBER = 'XN',
  DUPLICATE_EXCHANGE_ORDER_NUMBER = 'XO',
  SELLER_DOES_NOT_BELONG_TO_EXCHANGE = 'XS',
  INVALID_TRANSACTION_TYPE_XT = 'XT',
  SELLER_EXCHANGE_DATE_DIFFERENCE_EXCEEDED = 'XW',
  BUYER_SESSION_TIMEOUT_AT_INTERNET_BANKING_LOGIN_PAGE = '1A',
  BUYER_FAILED_TO_PROVIDE_THE_NECESSARY_INFO_TO_LOGIN_TO_INTERNET = '1B',
  BUYER_CHOOSE_CANCEL_AT_LOGIN_PAGE = '1C',
  BUYER_SESSION_TIMEOUT_AT_ACCOUNT_SELECTION_PAGE = '1D',
  BUYER_FAILED_TO_PROVIDE_THE_NECESSARY_INFO_AT_ACCOUNT_SELECTION_PAGE = '1E',
  BUYER_CHOOSE_CANCEL_AT_ACCOUNT_SELECTION = '1F',
  BUYER_SESSION_TIMEOUT_AT_TAC_REQUEST_PAGE = '1G',
  BUYER_FAILED_TO_PROVIDE_THE_NECESSARY_INFO_AT_TAC_REQUEST_PAGE = '1H',
  BUYER_CHOOSE_CANCEL_AT_TAC_REQUEST_PAGE = '1I',
  BUYER_SESSION_TIMEOUT_AT_CONFIRMATION_PAGE = '1J',
  BUYER_FAILED_TO_PROVIDE_THE_NECESSARY_INFO_AT_CONFIRMATION_PAGE = '1K',
  BUYER_CHOOSE_CANCEL_AT_CONFIRMATION_PAGE = '1L',
  INTERNET_BANKING_SESSION_TIMEOUT = '1M',
  TRANSACTION_AMOUNT_IS_LOWER_THAN_MINIMUM_LIMIT = '2A',
  DRAFT = '-5',
  SESSION_TIMEOUT_AT_FPX_ENTRY_PAGE = 'OA',
  TRANSACTION_IS_CANCELLED_BY_MERCHANT = '2X',
  AWAITING_AUTHORISATION = '-6',
  UNKNOWN_ERROR = 'C9999',
  FAILED = 'CRR',
  PAPER_MANDATE_FAILED = 'X9999'
}

export enum CurlecMerchantMethod {
  MANDATE = '00',
  INSTANT_PAY = '01'
}

