import { Button, Center, Heading, VStack } from '@chakra-ui/react';

export const Landing = () => {
  return (
    <>
      <Center mt="250">
        <VStack>
          <Heading>LANDING PAGE</Heading>
          <Button bg="blue.300">CLICK ME</Button>
        </VStack>
      </Center>
    </>
  );
};
