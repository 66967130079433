export interface PaginationProps {
  perPage: number;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  onPageChange(selectedItem: { selected: number }): void;
}

export enum PaginationClassNames {
  CONTAINER = 'paginationContainer',
  NEXT_AND_PREV_LABEL = 'nextAndPrevLabel',
  LIST_ITEM = 'paginationListItem',
  LIST_ITEM_LINK = 'paginationListItemLink',
  ACTIVE_LIST_ITEM = 'paginationActiveListItem',
  ELLIPSIS = 'paginationEllipsis'
}
