import { Button, Icon, Modal, ModalBody, ModalContent, ModalOverlay, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type ConfirmationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  icon?: React.ReactElement;
  title: string;
  body?: string;
  confirmButton: React.ReactElement;
  closeOnOverlayClick?: boolean;
  isCentered?: boolean;
  closeButtonText: string;
};

export const ConfirmationDialog = ({
  isOpen,
  onClose,
  icon,
  title,
  confirmButton,
  closeOnOverlayClick = true,
  isCentered = true,
  closeButtonText
}: ConfirmationDialogProps) => {
  const { t } = useTranslation('form');
  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={closeOnOverlayClick} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt="25px">
          <Flex flexDir="column" justifyContent="center" alignItems="center" gap="28px">
            {icon ? icon : <Icon />}
            <Heading variant="titleOne">{title}</Heading>
          </Flex>
          <Flex flexDir="column" justifyContent="center" alignItems="center" width="100%" mt="24px" mx="auto">
            {confirmButton}
            <Button variant="transparent" width="100%" onClick={onClose} color="primary.500" mb="40px">
              {t(closeButtonText)}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
