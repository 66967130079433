export enum FontFamily {
  DM_SANS = 'DM Sans',
  INTER = 'Inter'
}

export enum BusinessInformationItemsDeleteType {
  CO_APPLICANT = 'Co-Applicant',
  LITIGATION = 'Litigation'
}

export enum Prefix {
  PHONE_NUMBER = '0'
}

export enum AssetTypesLabel {
  PROPERTIES = 'Properties',
  LUXURY_WATCHES = 'Luxury Watches',
  SHIPMENT_GOODS_AND_CARGO = 'Shipment Goods & Cargo',
  GOLD_JEWELLERY_GOLD_BARS_GOLD_COINS = 'Gold Jewellery, Gold Bars, Gold Coins',
  SALES_PURCHASE_AGREEMENT = 'Sales & Purchase Agreement',
  GOODS_AND_CARGO_DETAILS = 'Goods & Cargo Details',
  INVOICE_SLIP = 'Invoice Slip',
  WEIGHT_OF_GOLD = 'Weight of Gold',
}
