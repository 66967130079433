import { VStack, Flex, Heading, Box, Link, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationResponse, NotificationStatus, NotificationType, TurnkeyLoanStatus } from '../types';
import { FontFamily } from '../../../utils/constants';
import useDateFormatter, { DateFormat } from '../../../hooks/useDateFormatter';
import { useMarkSingleNotificationAsReadMutation } from '../api/getNotifications';

type NotificationProps = {
  id: string;
  loanId: string;
  message: string;
  createdDate: Date;
  status: string;
  updatedDate: Date;
  seeMoreText: string | undefined;
  type: NotificationType;
  loanMilestone: NotificationType;
  turnkeyLoanStatus: TurnkeyLoanStatus;
  loanName: string;
};

export const Notification = ({
  id,
  loanId,
  message,
  createdDate,
  status,
  updatedDate,
  seeMoreText,
  type,
  loanMilestone,
  turnkeyLoanStatus,
  loanName
}: NotificationProps) => {
  const { t } = useTranslation('cta');

  const { formatDate } = useDateFormatter();
  const readNotificationMutation = useMarkSingleNotificationAsReadMutation();

  const handleReadNotification = async () => {
    if (status === NotificationStatus.Unread) {
      await readNotificationMutation.mutateAsync(id ? id : '');
    }
  };

  let translatedMessage;

  switch (true) {
    case loanMilestone === NotificationType.Submitted &&
      type === NotificationType.Submitted &&
      turnkeyLoanStatus === TurnkeyLoanStatus.WaitingForApproval:
      translatedMessage = t('loanCreation', { loanName });
      break;

    case loanMilestone === NotificationType.EMandate &&
      type === NotificationType.Approved &&
      turnkeyLoanStatus === TurnkeyLoanStatus.BankAccountVerification:
      translatedMessage = t('loanApproved', { loanName });
      break;

    case loanMilestone === NotificationType.ESigning &&
      type === NotificationType.EMandate &&
      turnkeyLoanStatus === TurnkeyLoanStatus.WaitingForCustomerSignature:
      translatedMessage = t('loanEMandate', { loanName });
      break;

    case loanMilestone === NotificationType.ESigning &&
      type === NotificationType.ESignatureCertificateEnrolment &&
      turnkeyLoanStatus === TurnkeyLoanStatus.WaitingForCustomerSignature:
      translatedMessage = t('loanESigning', { loanName });
      break;

    case loanMilestone === NotificationType.VideoAttestation &&
      type === NotificationType.ESigningApproved &&
      turnkeyLoanStatus === TurnkeyLoanStatus.VideoAttestation:
      translatedMessage = t('loanESigningApproved', { loanName });
      break;

    case loanMilestone === NotificationType.Disbursement &&
      type === NotificationType.VideoAttestationApproved &&
      turnkeyLoanStatus === TurnkeyLoanStatus.InitialPaymentsPreDisbursement:
      translatedMessage = t('videoAttestation', { loanName });
      break;

    case loanMilestone === NotificationType.Servicing &&
      type === NotificationType.Disbursement &&
      turnkeyLoanStatus === TurnkeyLoanStatus.Approved:
      translatedMessage = t('loanSummary', { loanName });
      break;

    default: // Use the original message if no translation is available
      translatedMessage = message;
      break;
  }

  // const translateMessage = (message: string) => {
  //   const words = message.split(' ');

  //   const translatedWords = words.map((word: string) => {
  //     const translatedWord = t(word);
  //     return translatedWord !== word ? translatedWord : word;
  //   });

  //   return translatedWords.join(' ');
  // };

  // const translatedMessage = translateMessage(message);

  return (
    <>
      <VStack w="100%" alignItems="flex-start" mt="20px" pb="20px" borderBottom="1px solid" borderColor="neutral.200">
        <Flex w="100%" gap="16px" mb="8px" justifyContent="space-between">
          <Heading variant="titleThree" fontWeight={status === NotificationStatus.Read ? 500 : 600}>
            {translatedMessage}
          </Heading>
          <Text variant="caption" color="neutral.500" flexShrink={0}>
            {formatDate({ date: createdDate as Date | string, format: DateFormat.DDMMM })}
          </Text>
        </Flex>
        <Flex w="100%" justifyContent="space-between" alignItems="center" mt="0 !important">
          <Link
            as={NavLink}
            to={`/loans/${loanId}`}
            color="primary.500"
            fontFamily={FontFamily.INTER}
            fontWeight={500}
            onClick={handleReadNotification}
            fontSize="16px"
            lineHeight="120%"
          >
            {t(`${seeMoreText}`)}
          </Link>
          {status === NotificationStatus.Read ? '' : <Box display="block" w="12px" h="12px" bg="negative.500" rounded="100%" />}
        </Flex>
      </VStack>
    </>
  );
};
