import { Box, Flex, Heading, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FontFamily } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { NotificationResponse, NotificationType } from '../types';
import { Notification as SingleNotification } from '../components/Notification';
import { useGetNotifications, useMarkAllNotificationsAsReadMutation } from '../api/getNotifications';

export const getNotificationDetailsTitle = (notificationType: string, creditProductName: string, turnkeyId: string) => {
  switch (notificationType) {
    case NotificationType.Submitted || NotificationType.Declined:
      return 'See More';

    case NotificationType.Approved ||
      NotificationType.EMandateApproved ||
      NotificationType.ESignatureCertificateEnrolment ||
      NotificationType.ESignatureCertificateEnrolmentApproved ||
      NotificationType.ESigning ||
      NotificationType.ESigningApproved ||
      NotificationType.VideoAttestation ||
      NotificationType.VideoAttestationApproved ||
      NotificationType.Disbursement:
      return `${creditProductName} #${turnkeyId}. Click here to see the details`;

    default:
      return 'See More';
  }
};

export const Notifications = () => {
  const { t } = useTranslation('cta');

  // Code below has been tested with mock api, uncomment these only when Notifications API is integrated.
  const [page, setPage] = useState(1);
  const { data: notificationsList, isLoading } = useGetNotifications({ page, limit: 10 });
  // const { data: notificationsList, isLoading, fetchNextPage, isFetchingNextPage } = useGetNotifications({ page, limit: 10 });

  // const loadNextPage = () => {
  //   setPage((prev) => prev + 1);
  // };

  // useEffect(() => {
  //   fetchNextPage({
  //     pageParam: page
  //   });
  // }, [page]);

  // const observer = useRef<IntersectionObserver>();
  // const lastNotificationRef = useCallback(
  //   (node: any) => {
  //     if (isLoading || isFetchingNextPage) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting) {
  //         loadNextPage();
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [isFetchingNextPage]
  // );

  const readAllNotificationsMutation = useMarkAllNotificationsAsReadMutation();
  const handleReadAll = () => {
    readAllNotificationsMutation.mutate();
  };

  return (
    <>
      <Flex flexDir="column" maxW="634px">
        <HStack spacing="16px" justifyContent="space-between" alignItems="center" pt="16px" pb="8px" mb={{ base: '4px', lg: '20px' }}>
          <Heading variant={{ base: 'headlineTwo', lg: 'headlineOne' }} fontFamily={FontFamily.INTER} fontWeight="bold">
            {t('Notifications')}
          </Heading>
          <Link
            href="#"
            color="primary.500"
            fontFamily={FontFamily.INTER}
            fontWeight={600}
            fontSize="18px"
            lineHeight="120%"
            onClick={handleReadAll}
            pt={{ base: '5px', md: '10px' }}
          >
            {t('Mark all as read')}
          </Link>
        </HStack>
        {notificationsList && notificationsList?.length > 0 && (
          <VStack spacing="20px" bg="neutral.0" py="16px" px="20px" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.12);" borderRadius="12px">
            {notificationsList.map(
              ({ id, loanId, message, createdDate, updatedDate, status, type, loan, loanMilestone, turnkeyLoanStatus }, index) => (
                <SingleNotification
                  key={index}
                  id={id}
                  loanId={loanId}
                  message={message}
                  createdDate={createdDate}
                  status={status}
                  updatedDate={updatedDate}
                  type={type}
                  loanName={`${loan.creditProductName} ${loan.turnkeyId}`}
                  loanMilestone={loanMilestone}
                  turnkeyLoanStatus={turnkeyLoanStatus}
                  seeMoreText={getNotificationDetailsTitle(type, loan.creditProductName, loan.turnkeyId)}
                />
              )
            )}
          </VStack>
        )}

        {/* {data ? (
          <VStack spacing="20px" bg="neutral.0" py="16px" px="20px" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.12);" borderRadius="12px">
            {data.pages.map((page, pageIndex) =>
              page.map(({ id, loanId, title, createdDate, isUnread }, index) => {
                const isLast = data.pages.length === pageIndex + 1 && page.length === index + 1;
                return (
                  <Box key={index} ref={isLast ? lastNotificationRef : null}>
                    <SingleNotification id={id} loanId={loanId} title={title} createdDate={createdDate} isUnread={isUnread} />
                  </Box>
                );
              })
            )}
          </VStack>
        ) : (
          ''
        )} */}
        {/* {(isFetchingNextPage || isLoading) && (
          <Text display="flex" justifyContent="center" variant="caption" color="neutral.500" my="30px" fontWeight={500}>
            Loading...
          </Text>
        )} */}
      </Flex>
    </>
  );
};
