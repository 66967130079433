import { Box, Flex, StyleProps } from '@chakra-ui/react';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';
import { MobileNavbar } from '../../features/Misc/components/MobileNavbar';
import { createContext, useContext, useState } from 'react';
import { useMatch } from 'react-router-dom';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayoutContext = createContext<{
  setStyleProps: (style: StyleProps) => void;
}>({
  setStyleProps: () => {
    throw 'Context is not initialized';
  }
});
export const useMainLayoutContext = () => {
  const context = useContext(MainLayoutContext);
  if (context === undefined) {
    throw new Error('useMainLayoutContext must be within MainLayoutProvider');
  }
  return context;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const showMobileNavbar = !useMatch('/loans/apply');
  const [styleProps, setStyleProps] = useState<StyleProps>();

  return (
    <MainLayoutContext.Provider value={{ setStyleProps }}>
      <Flex
        pos="relative"
        justify-content="center"
        mx="auto"
        paddingTop={{ base: 0, lg: '26px' }}
        paddingLeft={{ base: 0, lg: '27px' }}
        paddingBottom={{ base: '100px', lg: 0 }}
        flexDirection={{ base: 'column', lg: 'row' }}
        {...styleProps}
      >
        {showMobileNavbar && (
          <Box display={{ base: 'flex', lg: 'none' }} position="relative" zIndex="10" height="68px" backgroundColor="neutral.50">
            <MobileNavbar />
          </Box>
        )}
        <Sidebar />
        <Flex
          pos="relative"
          w="100%"
          px={{ base: '16px', lg: '60px' }}
          mt={{ base: '32px', lg: '28px' }}
          flexDir="column"
          justifyContent="space-between"
          flex="1"
          overflow="clip"
        >
          <Box>{children}</Box>
          <Box width="100%" margin="0 auto">
            <Footer />
          </Box>
        </Flex>
      </Flex>
    </MainLayoutContext.Provider>
  );
};
