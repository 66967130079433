import { extendTheme } from '@chakra-ui/react';
import { globalStyles } from './theme/foundations/Global';
import { globalColors } from './theme/foundations/Colors';
import { Button } from './theme/components/Button';
import { Heading, Text } from './theme/foundations/Typography';
import { Checkbox } from './theme/components/Checkbox';
import { Table } from './theme/components/Table';
import { Badge } from './theme/components/Badge';
import { Switch } from './theme/components/Switch';
import { Alert } from './theme/components/Alert';
import { Progress } from './theme/components/Progress';
import { Tabs } from './theme/components/Tab';
import { Accordion } from './theme/components/Accordion';
import { Select } from './theme/components/Select';
import { FontFamily } from './utils/constants';
import { CustomPagination } from './theme/components/CustomPagination';

const overrides = {
  styles: globalStyles,
  ...globalColors,
  components: {
    Button,
    Heading,
    Text,
    Checkbox,
    Table,
    Badge,
    Switch,
    Alert,
    Progress,
    Tabs,
    Accordion,
    Select,
    CustomPagination
  },
  fonts: {
    heading: `'${FontFamily.INTER}', sans-serif`,
    body: `'${FontFamily.DM_SANS}', sans-serif`
  }
};

export default extendTheme(overrides);
