import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Flex,
  Heading,
  Text,
  ModalCloseButton,
  Center,
  Divider,
  Box
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import useDateFormatter, { DateFormat } from '../../../../hooks/useDateFormatter';
import { FontFamily, BusinessInformationItemsDeleteType } from '../../../../utils/constants';
import { DraftLoan } from '../../shared/types';

type props = {
  draft?: DraftLoan;
  onClose: string;
  draftName: string;
  modalOpen: boolean;
  isCentered?: boolean;
  draftSubHeading: string;
  handleDelete: () => void;
  closeOnOverlayClick?: boolean;
  setModalOpen: (toggleOption: boolean) => void;
};

export const ApplyLoanConfirmationModal = ({
  draft,
  onClose,
  draftName,
  modalOpen,
  handleDelete,
  setModalOpen,
  draftSubHeading,
  isCentered = true,
  closeOnOverlayClick = true
}: props) => {
  const { t } = useTranslation('common');
  const { formatDate } = useDateFormatter();
  const navigate = useNavigate();

  const handleDraftRedirect = () => {
    navigate(`/loans/apply?loanId=${draft?.id}`);
    setModalOpen(false);
  };

  const handleStartNewApplication = () => {
    navigate('/loans/apply');
    setModalOpen(false);
  };

  return (
    <Modal onClose={() => setModalOpen(false)} isOpen={modalOpen} closeOnOverlayClick={closeOnOverlayClick} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent mx="20px" width={{ base: 'auto', md: '650px', lg: '650px' }}>
        <ModalCloseButton backgroundColor="neutral.100" />
        <ModalBody px="32px" py="20px">
          <Flex flexDir="column" justifyContent="center" alignItems="flex-start" gap="8px">
            <Heading
              color="neutral.800"
              textAlign="left"
              fontWeight={700}
              fontFamily={FontFamily.INTER}
              variant={{ base: 'titleTwo', lg: 'titleOne' }}
            >
              {draftName}
            </Heading>

            <Text variant="caption" color="neutral.500" fontFamily={FontFamily.DM_SANS}>
              {draftSubHeading}
            </Text>
          </Flex>
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            mt="32px"
            mx="auto"
            border="1px solid #EAECEE"
            borderRadius="8px"
          >
            {draft && (
              <Box display="flex" justifyContent="center" alignItems="center" py="16px">
                <Image src={`/assets/images/icons/file.svg`} objectFit={'cover'} width="32px" height="32px" />

                <Box
                  ml="8px"
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                  flexDirection="column"
                  cursor="pointer"
                  onClick={handleDraftRedirect}
                >
                  <Heading variant="subtitleSemiBold" color="neutral.800" fontFamily={FontFamily.INTER} fontWeight={600}>
                    {t(draft.creditProductName, { ns: 'common' })} {draft.draftId ? `#D-${draft.draftId}` : ''}
                  </Heading>
                  <Text variant="caption" color="neutral.500" fontFamily={FontFamily.DM_SANS}>
                    {t('Last edit') + ' ' + `${formatDate({ date: draft.updatedDate, format: DateFormat.DDMMMYYYY })}`}
                  </Text>
                </Box>
              </Box>
            )}
          </Flex>
          <Button variant="transparent" minH="48px" width="100%" onClick={handleStartNewApplication} color="primary.500" mt="25px">
            <Text variant="largeSubtitle" fontWeight={600}>
              {t(onClose)}
            </Text>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
