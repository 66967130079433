import { Box, Button, Flex, HStack, Heading, Progress, Text } from '@chakra-ui/react';
import { FontFamily } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { GenericLoan } from '../../../Loans/shared/types';
import { currencyFormatter } from '../../../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';

type ActiveLoansCardProps = {
  loan: GenericLoan;
};

export const ActiveLoansCard = ({ loan }: ActiveLoansCardProps) => {
  const { t } = useTranslation(['overview', 'common']);

  const navigate = useNavigate();

  return (
    <>
      {/* Take a parent flex with Padding: 24px; flex direction row; */}

      <Flex
        padding="24px"
        flexDir={{ base: 'column', sm: 'column' }}
        mt="24px"
        w={{ base: '100%', xl: '510px' }}
        bg="white"
        borderRadius="8px"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05)"
        gap="20px"
      >
        <Flex w={{ base: 'auto' }}>
          <Text
            mt="12px"
            bg="primary.100"
            py="6px"
            px="12px"
            borderRadius="6px"
            color="primary.500"
            fontFamily={FontFamily.INTER}
            variant="overlineTwo"
            textTransform="uppercase"
            fontWeight={600}
          >
            {loan.creditProductName} #{loan.turnkeyId}
          </Text>
        </Flex>

        <Flex gap="2px">
          <Box>
            <Text variant="bodyTwo" fontFamily={FontFamily.INTER} fontWeight="600" color="neutral.500">
              {t('Repayment')}
            </Text>
            <Heading variant="headlineTwo">{currencyFormatter.format(loan.repaymentTotalDue)}</Heading>
          </Box>
        </Flex>

        <Flex gap="12px" flexDir="column">
          <Progress width="100%" value={(loan.totalRepaidBalance / loan.loanAmountWithInterest) * 100} />
          <Flex justifyContent="space-between" marginTop={{ md: '0px', base: '12px' }}>
            <HStack>
              <Text
                variant="bodyTwo"
                fontFamily={FontFamily.INTER}
                fontWeight="600"
                color={loan.repaymentTotalDue === 0 ? 'neutral.500' : 'primary.500 '}
              >
                {currencyFormatter.format(loan.totalRepaidBalance)}
              </Text>
              <Text variant="bodyTwo" fontFamily={FontFamily.INTER} fontWeight="600" color="neutral.500">
                {t('of')} {currencyFormatter.format(loan.loanAmountWithInterest)}
              </Text>
            </HStack>
            <Text variant="bodyTwo" fontFamily={FontFamily.INTER} fontWeight="600" color="primary.500">
              {`${((loan.totalRepaidBalance / loan.loanAmountWithInterest) * 100).toFixed(0)}%`}
            </Text>
          </Flex>
        </Flex>

        <Flex width="auto">
          <Button
            type="button"
            mt="4px"
            size="md"
            variant="primary"
            width="auto"
            onClick={() => {
              navigate(`/loans/${loan.id}`);
            }}
          >
            {t('See Details')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
