export const loanKeys = {
  all: ['loans'] as const,
  lists: () => [...loanKeys.all, 'list'] as const,
  list: (filters: string) => [...loanKeys.lists(), { filters }] as const,
  details: () => [...loanKeys.all, 'detail'] as const,
  detail: (id: string) => [...loanKeys.details(), id] as const
};

export const loanDraftKeys = {
  all: ['loanDrafts'] as const,
  lists: () => [...loanKeys.all, 'list'] as const,
  list: (filters: string) => [...loanKeys.lists(), { filters }] as const,
  details: () => [...loanKeys.all, 'detail'] as const,
  detail: (id: string) => [...loanKeys.details(), id] as const
};

export const ekycKeys = {
  verifyEkycStatus: ['verifyEkycStatus'] as const,
  getEkycStatus: ['getEkycStatus'] as const
};

export const fileDetailKeys = {
  getFileDetails: (id: string) => [id] as const
};

export const paymentQueryKeys = {
  paymentMethod: ['payment-methods'] as const,
  payment: ['payment'] as const
};

