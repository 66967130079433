import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useCustomToast from '../../../hooks/useCustomToast';
import { axios } from '../../../lib/axios';
import { ActiveLoan } from '../shared/types';
import { useTranslation } from 'react-i18next';

export const getActiveLoans = async (): Promise<ActiveLoan[]> => {
  return await axios.get('/loans/active');
};

export const useGetActiveLoansQuery = () => {
  const { t } = useTranslation('form');
  const { addCustomToast } = useCustomToast();

  return useQuery({
    keepPreviousData: true,
    queryKey: ['activeLoans'],
    queryFn: () => getActiveLoans(),
    onError: (err) => {
      if (err instanceof AxiosError) {
        addCustomToast({
          title: t('Something went wrong'),
          status: 'error'
        });
      }
    }
  });
};
