import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Footer } from '../../../../components/Layout';
import { CustomFade } from '../../../../components/Motion';
import { FontFamily } from '../../../../utils/constants';

type AuthBodyLayoutProps = {
  header: string;
  subHeader?: string;
  children: React.ReactNode;
};

export const AuthBodyLayout = ({ header, subHeader, children }: AuthBodyLayoutProps) => (
  <>
    <Flex flex="1">
      <Box paddingTop="201px" paddingLeft={{ base: '22px', md: '175px' }} paddingRight="22px" overflow="hidden">
        <CustomFade style={{ width: '100%' }}>
          <Flex flexDir="column" gap="16px">
            <Heading fontSize="34px" fontWeight="bold">
              {header}
            </Heading>
            {subHeader && (
              <Text variant="subtitle" fontWeight={500} color="neutral.500" fontFamily={FontFamily.INTER}>
                {subHeader}
              </Text>
            )}
          </Flex>
          {children}
        </CustomFade>
      </Box>
    </Flex>
    <Box width="100%" maxWidth={{ sm: 'none', lg: '80vw' }} padding={{ base: '0 16px', lg: '0' }} margin="0 auto">
      <Footer />
    </Box>
  </>
);
